import React from 'react'
import { Grid } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import 'App.css'
import Dalee from 'components/DaleeBtn';

export default function BlockDiagram(){


    return (
        <><h1><ElectricBoltIcon /> Принцип работы системы</h1>
            <p>
                Ниже представленна схема работы TVCAS4 совместно со скремблером <a href="https://cesbo.com" target_="_blank" rel="noreferrer">Cesbo Astra</a>.
                В простейшем случае устанавливаются два соединения между ECMG / EMMG и скремблером Astra.
                Стрелками показан действительный инициатор соединения. Так, стрелка влево "connect 1" говорит о том, что скремблер подключается к ECMG,
                а стрелка вправо "connect 2" об обратном, - что EMMG подключается к порту скремблера.
            </p>

            <center><img src='/sources/block_diagram_tvcas.svg' alt='tvcas block diagram' width="60%" /></center>

            <p>Для передачи ECM-пакета требуется успешное установление <b>подключения 1</b>. Скремблер генерирует ключи CW1 и CW2, передает их генератору ECMG,
            а последний формирует ECM-пакет, который впоследствии инкапсулируется в поток с заданным в скремблере PID-ом.
            Этот зашифрованный ECM-пакет содержит четыре поля данных: <b>текущее время</b> [4 байта], <b>ключи CW1 и CW2</b> (8+8 байт), <b>критерий доступа</b> (4 байта)
            и <b>ecmgift</b> (48 байт). Пакет ECM предназначается для всех смарткарт.</p>

            <p>EMMG генерирует пакеты для каждой <u>прошитой</u> смарткарты которая существует в базе данных и передает их на скремблер по <b>подключению 2</b>.
            Таким образом, каждый пакет EMM предназначен только для определенной смарт-карты. Скорость отправки EMM - около 150 в минуту. Причём, если 
            информация у смарткарты была изменена в интерфейсе или по API, то EMM-пакет уйдёт на скремблер немедленно.</p>

            <Grid container spacing={2}>
                <Grid item xs={6}><center><img src='/sources/ecm_packet_tvcas.jpg' alt='ECM-packet' style={{maxWidth:"550px"}} /><br />ECM-пакет TVCAS</center></Grid>
                <Grid item xs={6}><center><img src='/sources/emm_packet_tvcas.jpg' alt='EMM-packet' style={{maxWidth:"550px"}} /><br />EMM-пакет TVCAS</center></Grid>
            </Grid>

            <p>
                Данные в пакетах шифруются с использованием алгоритма, аналогичного <a href='https://en.wikipedia.org/wiki/Triple_DES' target='_blank' rel="noreferrer" >Triple DES</a> (3DES-ECB).
                Он использует <a href='https://youtu.be/mE_s-R5wvpw?t=2534' target='_blank' rel="noreferrer" >сеть Фейстеля</a> и многораундовый сдвиг битов. Секрет здесь только КЛЮЧ.
                Без него расшифровать пакет невозможно. Ключи находятся в базе данных сервера TVCAS и на запрограммированных смарт-картах.
                Если со смарткартами все безопасно - предохранители защищены от чтения кода и памяти, то с сервером гораздо сложнее - беспокойтесь о сохранности ключей,
                начиная от персонала, заканчивая использованием для этой цели только локальной машины без доступа к Интернет!
            </p>

            <Dalee url="install" />

        </>
    )
}