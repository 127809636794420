import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle, Typography } from '@mui/material';
import 'App.css'
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';

export default function Features(){
    const ans = '{"serial_no":"2100000000","descr":"Jack London","access_criteria":"00000001","pair":"0","start":"1234567890","finish":"1234567890"}';


    return (
        <><h1><ElectricBoltIcon /> Возможности TVCAS4</h1>

            <ul>
                <li>Максимальное количество гарантированных абонентов до 1 млн. (больше не тестировалось)</li>
                <li>Количество кодируемых каналов <b>255 для одного ECMG</b></li>
                <li>Количество ECMG и EMMG ограничено доступностью ваших портов в операционной системе</li>
                <li>Использование смарткарт <b>THC20F17BD</b> или более современных их аналогов <b>THC20F150A</b></li>
                <li>Максимальное количество реализуемых пакетов телепрограмм (классы) - 31</li>
                <li>Интеграция с биллингом посредством CSV файлов экспорта/импорта</li>
                <li>Простое API для управления подписками на смарткартах</li>
                <li>Функция <b>ECMGIFT</b>, внедряющая подписки в пакеты ECM</li>
                <li>Функции блокировки смарткарты при попытке нестандартного использования</li>
                <li><ImageModal url="/sources/VirtualCAM.svg">Виртуальный CAM</ImageModal> - работа с TVCAS4 без использования смарткарт</li>
                <li>Радар смарткарт для противодействия шаринга ключей</li> 
                <li>PIN-код родительского контроля и возможность его удалённого сброса (по умолчанию <b>1234</b>)</li>
                <li>Рассылка всплывающих сообщений (SMS) на экран абонента (до 47 символов в латинице)</li>
                <li><ImageModal url="/sources/smartcard_arm64.jpg">Виртуальные смарткарты</ImageModal> для платформы ARM64</li>
                <li>Удалённая смена ECM/EMM ключей на физических и виртуальных смарткартах</li>
                <li>Режим приоритетных EMM в генераторе (актуально при использовании более 50 тысяч смарткарт)</li>
            </ul>

            <Alert severity="info" >
                <AlertTitle>Пример API-запроса:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>serial_no</b>=2100000000&<b>set[descr]</b>=Jack%20London&<b>set[pair]</b>=0&<b>set[start]</b>=1234567890&<b>set[finish]</b>=1234567890&<b>set[access_criteria]</b>=00000001
                </code>
                <AlertTitle style={{marginTop:"10px"}}>Информация о newcamd:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>newcamd</b>= connections || banned || unbann || smartcards
                </code>
                <AlertTitle style={{marginTop:"10px"}}>Немедленно отправить SMS:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>serial_no</b>=2100000000&<b>sms</b>=This%20is%20my%20important%20info%20for%20client!
                </code>
            </Alert>

            <p>Параметры <b>set[x]</b> необязательны. Если они полностью отсутствуют, в ответ будет возвращена информация о карте в формате json. Если был передан один или несколько параметров <b>set[x]</b>, то сначала эти поля будут изменены в базе данных, а затем информация об этой карте будет считана и возвращена. Таким образом, ответ json будет содержать информацию, уже измененную, как и было запрошено.</p>

            <p>
                Обратите внимание, что SMS при запросе по API отправляется всего один раз (обычно в течение 10 секунд) и служит скорее для теста системы сообщений. Чтобы добавить сообщение в ротацию EMM, - используйте рассылку в панели управления TVCAS4.
                Также отметим friendly-CAM для зрителя: всплывающее сообщение не может быть показано повторно в течение сеанса работы CAM-модуля. Но автору встречалась информация, что такое ограничение отсутствует в некоторых STB.
            </p>
        

            <Alert severity="warning" style={{margin:"30px 0"}} >
                <AlertTitle>Используйте API-ключ состоящий из 16 символов (буквы и цифры)!</AlertTitle>
            </Alert>
            
            <Alert severity="info" style={{marginBottom: "20px"}} >
                <AlertTitle>Пример ответа API:</AlertTitle>
                <code>{ans}</code>
            </Alert>

            <Alert severity="error">
                <AlertTitle>Ошибки ответа API</AlertTitle>
                <span className="console">
                    <b>NOT_VALID_API_KEY</b> — неправильный API-ключ. Проверьте его со значением в CONFIG<br />
                    <b>SMARDCARD_NOT_FOUND</b> — смарткарта не найдена в базе данных TVCAS<br />
                    <b>UNKNOWN_SET_PARAMETER</b> — один или более параметров неизвестен системе<br />
                    <b>ACCESS_CRITERIA_ERROR</b> — параметр <u>access_criteria</u> не находится в области <code>00000000-FFFFFFFF</code><br />
                    <b>PAIR_ERROR</b> — ошибка установки флага PAIR (возможные значение 0 или 1)<br />
                    <b>START_ERROR</b> или <b>FINISH_ERROR</b> — неправильная установка Unix времени (10 цифр) 
                </span>
            </Alert>

            <h1><ElectricBoltIcon /> Использование MySQL</h1>
            <p>По умолчанию, в TVCAS4 используется база данных SQLite3, работающая на одном файле в рабочей директории <b>./db.sqlite3</b>.
            При использовании большого количества смарткарт (более 1000) рекомендуется использовать MySQL.
            Поддержка её реализована начиная с версии 4.025.</p>
            <p><b>1. Установить на сервере MySQL (MariaDB):</b><br />
    <code>apt install mariadb-server<br />
    systemctl start mariadb<br />
    mysql_secure_installation </code> #создайте пароль root и др.</p>
            
            <p><b>2. Создайте базу данных и пользователя для TVCAS4:</b><br />
                <code>CREATE USER 'tvcas4user'@'localhost' IDENTIFIED BY 'tvcas4pass';<br />
                CREATE DATABASE tvcas4db CHARACTER SET utf8 COLLATE utf8_general_ci;<br />
                GRANT ALL PRIVILEGES ON tvcas4db.* TO 'tvcas4user'@'localhost';<br />
                FLUSH PRIVILEGES;<br />
                SHOW GRANTS FOR 'tvcas4user'@'localhost';</code></p>
                
            <p><b>3. Экспортируйте файл `smartcards.csv` со своими смарткартами через соответствующую функцию в панели управления TVCAS4.</b></p>

            <p><b>4. Создайте в рабочей директории файл Data Source Name `./mysql.dsn` с содержимым:</b><br />
                <code>tvcas4user:tvcas4pass@tcp(127.0.0.1:3306)/tvcas4db</code></p>
                
            <p><b>5. Перезапустите сервер TVCAS4:</b><br />
                <code>systemctl restart tvcas4</code></p>

            <p><b>6. Зайдите в панель управления TVCAS4, импортируйте `smartcards.csv`, создайте пакеты программ и генераторы.</b></p>

            <Alert severity="warning" style={{margin:"30px 0"}} >
                <AlertTitle>Таким образом, чтобы использовать MySQL у вас должен быть файл в корне <code>./mysql.dsn</code> с параметрами подключения к базе данных.</AlertTitle>
                При его отсутствии или некорректном подключении к MySQL используется по умолчанию <b>SQLite3</b>.
            </Alert>
            <Alert severity="success" style={{margin:"30px 0"}} >
                Если вы используете <b>systemd</b> для автозапуска сервера TVCAS4, то добавьте в файл сервиса выделенный текст:
                <div style={{backgroundColor:"#555", margin:"10px 20px", color:"#ccc", padding:"10px 20px", fontFamily:"Consolas"}}>[Unit]<br />
Description=tvcas4<br />
After=network.target <b style={{color:"#ff87a5"}}>mariadb.service</b><br />...</div>
            </Alert>

            <h1><ElectricBoltIcon /> Рекомендации</h1>
                        
                <Typography variant="h6">Технические требования</Typography>
                <ul>
                        <li><b>Сервер</b>: 1 ядро CPU, 1 Гбайт ОЗУ, 100 Мбайт места на HDD, Debian 11 или новее</li>
                        <li><b>Аплоадер</b>: windows 10 или новее</li>
                        <li><b>Скремблер</b>: <a href="https://cdn.cesbo.com/astra" target="_blank" rel="noreferrer">последняя Cesbo Astra</a> (другие скремблеры не тестировались)</li>
                </ul>

                <Typography variant="h6" style={{marginTop:"50px"}}>Безопасность</Typography>

                <p>Использование данной CAS, при осуществлении услуг телевидения, подразумевает, что Вы понимаете все риски и возможный уровень утечки в безопасности системы.
                    С точки зрения программного кода автор гарантирует, что данная утечка не может быть осуществлена воздействием внешними средствами на систему.
                    Однако, несмотря на то, что ключи в базе данных хранятся в зашифрованном виде, осторожность должен вызывать именно человеческий фактор или т.н. социальная инженерия (ESET), при которых ваши ECM/EMM-ключи могут быть скомпрометированы.
                </p>
                <ul style={{marginTop:"10px"}}>
                        <li>Не рекомендуется открывать порты с доступом к глобальной сети! В идеальном случае необходимо отключить интернет на сервере, где установлена система TVCAS4.</li>
                        <li>После первого же запуска изменить пароли для пользователей <b>admin</b> и <b>oper</b></li>
                        <li>Используйте доступ <b>admin</b> только для начальной установки системы и при программировании/стирании смарткарт</li>
                        <li>Обеспечьте доступ к системе ограниченному кругу лиц. Если у вас отсутствует биллинг, то можно предоставить доступ оператору через пользователя <b>oper</b>,
                        но лучшее решение - это подключение системы к внешним программам через обмен по API</li>
                </ul>

                <Typography variant="h6" style={{marginTop:"50px"}}>Прошивка/стирание смарткарт</Typography>
                <p>
                    Процесс прошивки и стирания смарткарт не носит каких-либо сложностей, но стоит учитывать одно нехорошее свойство <b>Windows</b>.
                    Так, если консольное приложение оказывается в фоне, то его работа может притормаживаться для направления ресурсов другим процессам.
                    Если вы отправили команду на прошивку/стирание смарткарты, а счётчик в <b>uploader</b> "застыл", то просто переведите фокус на консоль Windows
                    и нажмите любую клавишу (например "пробел") - процесс прошивки/стирания продолжится.
                </p>

                <Typography variant="h6" style={{marginTop:"50px"}}>Отыскание багов и неисправностей</Typography>
                <p>
                    Обычно, если вы следуете рекомендациям установки и эксплуатации TVCAS4, то проблем при работе возникать не должно.
                    Однако, зачастую, оператор начинает комбинировать различные варианты использования системы и из-за того, что такие случаи автором не предусматривались,
                    то вполне допустимо некорректное поведение. Сообщить о таких багах и проблемах можно в общую группу <a href='https://t.me/tvcas' target='_blank' rel='noreferrer'>@tvcas</a>.
                    Если проблема известная, то разрешить её помогут сами участники. Иначе автор выпустит обновление с исправлением.
                    Приоритет по обновлениям принадлежит пользователям с приобретённой лицензией.
                </p>
                <p>
                    <span style={{color:"red"}}>Внимание!</span> При отправке запроса о проблеме, пожалуйста, прикрепляейте сообщения лога и другие материалы которые помогут лучше понять проблему.
                    Сообщения вида: «У меня не работает (или не запускается) TVCAS4» будут просто игнорироваться, т.к. не содержат никакой дополнительной информации.
                    В случае если проблема касается работы генераторов EMM, то сперва убедитесь, добавляет ли скремблер эти EMM в ваш поток телеканала.
                    Сделать это можно с помощью консольной утилиты <ImageModal url="/sources/emmdetector.jpg">emmdetector</ImageModal>:
                </p>
                <Alert>
                    <code>
                    wget https://tvcas.com/sources/emmdetector<br />
                    chmod 766 ./emmdetector<br />
                    ./emmdetector
                    </code>
                </Alert>

                <p>
                    Также, для просмотра трафика (ECM/EMM) между скремблером и TVCAS4 рекомендуем использовать <a href='https://github.com/unidiag/cascap' target='_blank' rel='noreferrer'>CASCAP</a>.
                </p>


            <Dalee url="diagram" />

        </>
    )
}