import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import 'App.css'
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';
import { Grid } from '@mui/material';

export default function Interface() {
    return (
        <>
            <h1><ElectricBoltIcon /> Web Interface</h1>

            <p>
                The web interface for managing the TVCAS4 server consists of 6 (six) main sections:
            </p>

            <ul>
                <li><b>Smartcards</b> - the main page of the interface, displays a list and control functions for smart cards</li>
                <li><b>Packages</b> - list of program packages and their management</li>
                <li><b>ECMG</b> - ECM generators</li>
                <li><b>EMMG</b> - EMM generators</li>
                <li><b>Logs</b> - system logging</li>
                <li><b>Config</b> - TVCAS4 settings</li>
            </ul>

            <center><img src="/sources/ui-smartcards.jpg" alt="Smartcards TVCAS4" width="90%" /></center>

            <p>
                The main <b>Smartcards</b> page in the interface menu is designed to be as intuitive as possible.
                There are just a few points to stop at.
                For quick access control to TV programs, there are two functions:
                "Deactivate" - deactivates the subscription time (immediately disables access for the card),
                and "Activate" - when pressed, the subscription is extended until the end of the month.
                If clicked before the 15th of the <u>current</u> month, the selected smart card will be activated until the end of the <u>current</u> month;
                otherwise, if clicked after the 15th, the subscription will be extended until the end of the <u>next</u> month (billing periods).
            </p>
            <p>
                Each smart card's options include: editing its subscriptions, flashing, resetting to factory settings, and erasing the counter.
                The last two functions are only available after purchasing the TVCAS4 license.
            </p>

            <hr />

            <p>
                The <b>Packages</b> section is responsible for the TV program packages.
                After installing and initially configuring the system, the next step is to create the packages.
                Most TV operators need 3-5 TV program packages.
                However, the system allows up to 32 packages.
                Clicking on a number in the <b>Clients</b> column will count the number of subscribers who have access to that package.
                The <b>Access criteria</b> parameters must be set in the <ImageModal url="/sources/ac-astra.jpg">channel properties</ImageModal> on the scrambler.
                If the channel belongs to multiple program packages, the <b>Access criteria</b> for the channel should include the <ImageModal url="/sources/ac-astra2.jpg">combined result</ImageModal> of all necessary packages.
            </p>

            <center><img src="/sources/ui-packages.jpg" alt="Packages TVCAS4" width="80%" /></center>

            <hr />

            <p>
                TVCAS4 allows you to create as many generators as there are free ports.
                Their configuration is simplified and doesn't require much explanation.
                The only explanation is about the <b>GIFT</b> checkbox in ECMG. This option sends subscriptions in the ECM packet.
                It's recommended to use it on already working systems to prevent EMM disconnection on the subscriber's side.
            </p>

            <Grid container>
                <Grid item xs={6}>
                    <center><img src="/sources/ecmg.jpg" alt="ECMG TVCAS4" height="300px" /></center>
                </Grid>
                <Grid item xs={6}>
                    <center><img src="/sources/emmg.jpg" alt="ECMG TVCAS4" height="300px" /></center>
                </Grid>    
            </Grid>

            <hr />

            <Dalee url="smartcards" />

        </>
    )
}
