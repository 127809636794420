import React, { useState } from 'react';
import Typography from '@mui/material/Typography'


function RedirectHome(props) {
    const [sec, setSec] = useState(3);
  
    const IntV = setInterval(() => {
        setSec(sec-1)
        if(sec===1){
            clearInterval(IntV)
            window.location.href="/"
        }
    }, 1000);
  
    return (
      <div>
        <Typography>Вы будете перенаправлены на главную страницу через <b>{sec}</b> секунд...</Typography>
        {/* Добавьте любой контент или индикацию ожидания, если необходимо */}
      </div>
    );
  }
  
  export default RedirectHome;