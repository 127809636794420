import React, {  useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

var pairkey = "2015A98C06F8E358"
var vectorKey = "DAAFA9D795A4792E"


export default function PairCalc(){
    const [resultHash, setResulthash] = useState("B6957958EEEAC507")
    



    function updateResultHash(){
        fetch("/ext.php?p=" + pairkey +"&v="+vectorKey)
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // или response.text() если ожидается другой тип данных
        })
        .then(data => {
            setResulthash(data)
        })
        .catch(error => {
            // Обработка ошибок
            console.error('There was a problem with the fetch operation:', error);
        });
    }



    return(
        <Grid container sx={{ width: '100%', border:"1px solid #eaeaea", padding:"10px" }}>
            <Grid item xs={4}>
            <TextField
                label="pairkey"
                id="filled-hidden-label-small1"
                defaultValue={pairkey}
                variant="filled"
                size="small"
                inputProps={{ maxLength: 16 }}
                onChange={e => {
                    pairkey = e.target.value
                    updateResultHash()
                }}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="vectorKey"
                id="filled-hidden-label-small2"
                defaultValue={vectorKey}
                variant="filled"
                size="small"
                inputProps={{ maxLength: 16 }}
                onChange={(e) => {
                    vectorKey = e.target.value
                    updateResultHash()
                }}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="resultHash"
                id="filled-hidden-label-small3"
                value={resultHash}
                variant="filled"
                size="small"
                disabled
            />
            </Grid>
        </Grid>
    )
}

