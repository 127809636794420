import React from "react";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Alert, AlertTitle, Grid } from "@mui/material";
import Dalee from "components/DaleeBtn";
import PairCalc from "components/PairCalc";
import ImageModal from "components/ImageModal";


export default function Pairing(){

    const golang = `
    resultHash := OneWayHash([]byte{0x20,0x15,0xA9,0x8C,0x06,0xF8,0xE3,0x58}, []byte{0xDA,0xAF,0xA9,0xD7,0x95,0xA4,0x79,0x2E})
    // resultHash = []byte{0xB6,0x95,0x79,0x58,0xEE,0xEA,0xC5,0x07}
     
    func reverseArray(arr []byte) []byte {
        length := len(arr)
        for i := 0; i < length/2; i++ {
            j := length - i - 1
            arr[i], arr[j] = arr[j], arr[i]
        }
        return arr
    }
     
    func OneWayHash(pairkey []byte, vectorTable []byte) []byte {
        hash1 := binary.LittleEndian.Uint32(reverseArray(pairkey[:4]))
        hash2 := binary.LittleEndian.Uint32(reverseArray(pairkey[4:]))
        result := make([]byte, 8)
        for i := 0; i < 8; i++ {
            hash1 ^= (hash2 << 2) + uint32(vectorTable[i])
            hash2 ^= (hash1 << 2) + uint32(vectorTable[i])
            hash1 += uint32(vectorTable[i])
            hash2 += uint32(vectorTable[7-i])
        }
        binary.LittleEndian.PutUint32(result[:4], hash1)
        binary.LittleEndian.PutUint32(result[4:], hash2)
        return result
    }
    `

    const cpp = `
    #include <stdio.h>
    #include <stdint.h>
    #include <string.h>
     
    uint8_t* reverseArray(uint8_t arr[], int length) {
        for (int i = 0; i < length / 2; i++) {
            int j = length - i - 1;
            uint8_t temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
        }
        return arr;
    }
     
    void OneWayHash(uint8_t pairkey[], uint8_t vectorTable[], uint8_t result[]) {
        uint32_t *hash1_ptr = (uint32_t *)reverseArray(pairkey, 4);
        uint32_t *hash2_ptr = (uint32_t *)reverseArray(pairkey + 4, 4);
        uint32_t hash1 = *hash1_ptr;
        uint32_t hash2 = *hash2_ptr;
     
        for (int i = 0; i < 8; i++) {
            hash1 ^= (hash2 << 2) + vectorTable[i];
            hash2 ^= (hash1 << 2) + vectorTable[i];
            hash1 += vectorTable[i];
            hash2 += vectorTable[7-i];
        }
     
        *((uint32_t*)result) = hash1;
        *((uint32_t*)(result + 4)) = hash2;
    }
     
    int main() {
        uint8_t key[] = {0x20,0x15,0xA9,0x8C,0x06,0xF8,0xE3,0x58};
        uint8_t vec[] = {0xDA,0xAF,0xA9,0xD7,0x95,0xA4,0x79,0x2E};
        uint8_t result[8];
     
        OneWayHash(key, vec, result);
        for (int i = 0; i < sizeof(result); i++) {
            printf("%02X ", result[i]); // выведет: B6 95 79 58 EE EA C5 07
        }
        return 0;
    }
    `

    const csumm = `func csum(data []byte) byte {
    var result byte
    for _, b := range data {
        result ^= b
    }
    return 0x3F ^ result
}`




    return (
        <>
            <h1><ElectricBoltIcon /> Концепция сопряжения (паринга)</h1>

            <p>
                Так как протоколы сопряжения поддерживаемых CAM-модулей не являются открытыми, то мы разработали свой, при помощи которого можно реализовать работу смарткарты только в своём приёмнике (STB).
                На данной странице мы разместим описание его реализации.
            </p>
            <p>
                Концепция паринга TVCAS4 заключается в том, что на смарткарте и приёмнике хранится один и тот же ключ сопряжения <code>pairkey</code>,
                состоящий из <b>8 случайных байт</b>. Данный ключ генерируется смарткартой при "активации сопряжения" (первый запуск).
                Именно поэтому, первый запуск рекомендуется производить <b>оператором системы</b>, чтобы избежать перехвата <code>pairkey</code> абонентом
                при помощи спецсредств типа "граббера", цифрового анализатора и т.п. Сгенерированный смарткартой ключ паринга сохраняется в энергонезависимой
                памяти приставки (STB). Проверка истинности сопряжения происходит по результату <code>resultHash</code>, полученного в результате хеширования <code>pairkey</code>
                с таблицей векторов <code>vectorTable</code>, которая также случайным образом формируется смарткартой при каждой новой проверке паринга.
                Активация считается успешной, если <code>resultHash</code> от приёмника такой же как и <code>resultHash</code> рассчитанный внутри смарткарты.
            </p>



            <Grid container>
                <Grid item xs={6}>
                    <img src="/sources/diagram_pair_conax.svg" width="95%" alt="Pair tvcas4" />
                </Grid>
                <Grid item xs={6}>
                    <img src="/sources/diagram_pair_conax2.svg" width="95%" alt="Pair tvcas4" />
                </Grid>
            </Grid>

            <p>
                Алгоритм работы <b>после</b> активации принципиально не отличается от активации.
                Но вместо <code>pairkey</code> передаётся пустой массив из 8 байт <code>00 00 00 00 00 00 00 00</code>.
                В любом случае, APDU данного обмена необходимо проводить в момент инициализации смарткарты.
                Например, сразу после сигнала <code>ATR</code>, но не позже первых ECM. Иначе ответом на ECM будет ошибка:
            </p>

            <pre style={{padding:"10px 20px", backgroundColor:"#eaeaea", borderRadius:"3px"}}>&lt;    98 04<br />
&gt; DD CA 00 00 04<br />
&lt;    CA 31 02 00 00 90 00</pre>

            <p>
                Обратите внимание, что после проверки паринга карта отвечает 90 00 в любом случае, пройдена ли проверка или нет.
                Сделано это специально от «переборов» ключей, так же как и задержка в 50 мсек перед этим ответом.
                Режим паринга не является панацеей, однако в умелых руках оператора доставляет огромные неудобства любителям кардшаринга,
                ведь ключ <code>pairkey</code> можно менять со временем.. Также, при должном интересе со стороны производителей оборудования,
                имеется расширенная версия протокола сопряжения с динамическим <code>pairkey</code>.
                Общение на эту тему возможно только после подписания <u>соглашения о неразглашении</u> (NDA).
            </p>

            <Grid container>
                <Grid item xs={6}>
                    <Alert style={{marginRight:"10px"}} severity="info">
                        <AlertTitle>TVCAS4 (CON)</AlertTitle>
                        <pre>&gt; DD CB 00 00 10<br />
&lt; CB <span style={{color:"red", fontWeight:"bold"}}>CE 74 12 3C 05 AB D9 69</span> <span style={{color:"#02c0c0", fontWeight:"bold"}}>E1 8D 83 FC D7 61 E8 1D</span> 9000<br />
&gt; 13 08 <span style={{color: "#df157a", fontWeight:"bold"}}>14 B0 5B 18 29 64 62 8F</span>  <span style={{color:"gray"}}> (wait 50 msec)</span><br />
&lt; 90 00</pre>
                    </Alert>
                </Grid>
                <Grid item xs={6}>
                    <Alert severity="info">
                        <AlertTitle>TVCAS4 (IRD)</AlertTitle>
                        <pre>&lt; 3B9F210E49524445544F204143532056352E3895      <span style={{color:"gray"}}> ATR</span><br />
&gt; 0102CB000000 F7                               <span style={{color:"gray"}}> F7 (last byte) - control summ</span><br />
&lt; 01020000CB000010 <span style={{color:"red", fontWeight:"bold"}}>DAAFA9D795A4792E</span> <span style={{color:"#02c0c0", fontWeight:"bold"}}>2015A98C06F8E358</span> DF<br />
&gt; 0102CB010008 <span style={{color: "#df157a", fontWeight:"bold"}}>B6957958EEEAC507</span> 3A              <span style={{color:"gray"}}> (wait 50 msec)</span><br />
&lt; 0102 9000 CB010000 66</pre>
                    </Alert>
                </Grid>
            </Grid>

            <p>
                Последний байт в каждом запросе(ответе) APDU в TVCAS4 (IRD) - это контрольная сумма. Рассчитывается как XOR всех предыдущих байтов с байтом <code>0x3F</code> 
            </p>
            <SyntaxHighlighter language="go">{csumm}</SyntaxHighlighter>

            <h2>Программная реализация</h2>

            <PairCalc />

            <p>
                Для проведения тестов с парингом можно <ImageModal url="/sources/apdupair.jpg">использовать</ImageModal> консольную программу <a href="/apdupair.exe" target="_blank" rel="noreferrer">apdupair.exe</a>.
                Ниже представлена реализации <b>OneWayHash()</b> - односторонней функции хеширования ключа на разных языках программирования:
            </p>
            <h3>Golang</h3>
            <SyntaxHighlighter language="go">{golang}</SyntaxHighlighter>
            <h3>Язык C</h3>
            <SyntaxHighlighter language="c">{cpp}</SyntaxHighlighter>

            <h2><ElectricBoltIcon /> Индикатор паринга на смарткарте</h2>
            <p>Проверить включён ли паринг на смарткарте можно уже <ImageModal url="/sources/apdupair.jpg">вышеупомянутой</ImageModal> программой <a href="/apdupair.exe" target="_blank" rel="noreferrer">apdupair.exe</a>.
            Также на смарткарте [CON] в конце <ImageModal url="/sources/pair_con.jpg">trademark</ImageModal> будет установлен "+" - когда карта ожидает активацию паринга и "*" - когда паринг включён и активация пройдена.<br />
            На картах с прошивкой [IRD] информацию о паринге можно получить из <ImageModal url="/sources/pair_ird.jpg">uploader.exe</ImageModal>.</p>
            <Dalee url="license" />
        </>
    )
}