import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home'
import HomeEn from '../pages/HomeEn'
import BlockDiagram from '../pages/BlockDiagram'
import BlockDiagramEn from '../pages/BlockDiagramEn'
import Features from '../pages/Features'
import FeaturesEn from '../pages/FeaturesEn'
import Install from '../pages/Install'
import InstallEn from '../pages/InstallEn'
import License from '../pages/License'
import LicenseEn from '../pages/LicenseEn'
import Interface from '../pages/Interface'
import InterfaceEn from '../pages/InterfaceEn'
import Smartcards from '../pages/Smartcards'
import SmartcardsEn from '../pages/SmartcardsEn'
import NotFound from '../pages/NotFound'
import Pairing from '../pages/Pairing'
import PairingEn from '../pages/PairingEn' 


export default function RoutesUser(props){

    return(
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/en' element={<HomeEn />} />
            <Route path='/diagram' element={<BlockDiagram />} />
            <Route path='/en/diagram' element={<BlockDiagramEn />} />
            <Route path='/features' element={<Features />} />
            <Route path='/en/features' element={<FeaturesEn />} />
            <Route path='/install' element={<Install />} />
            <Route path='/en/install' element={<InstallEn />} />
            <Route path='/interface' element={<Interface />} />
            <Route path='/en/interface' element={<InterfaceEn />} />
            <Route path='/smartcards' element={<Smartcards />} />
            <Route path='/en/smartcards' element={<SmartcardsEn />} />
            <Route path='/pairing' element={<Pairing />} />
            <Route path='/en/pairing' element={<PairingEn />} />
            <Route path='/license' element={<License />} />
            <Route path='/en/license' element={<LicenseEn />} />
            <Route path='/*' element={<NotFound />} />
            <Route path='/en/*' element={<NotFound />} />
        </Routes>
    );
}