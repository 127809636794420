import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle } from '@mui/material';
import YouTube from 'react-youtube';
import 'App.css'
import ImageModal from 'components/ImageModal';
import Dalee from 'components/DaleeBtn';

export default function Features() {

    return (
        <>
            <h1><ElectricBoltIcon /> Smartcards</h1>

            <p>
                Security in the TVCAS4 system is ensured by the use of special smartcards, which are also used in the banking sector 
                and by mobile operators, such as <a href="http://tsinghuaic.com/product/product.asp?id=12&bid=8552" target="_blank" rel="noreferrer">THC20F17BD-V40</a>.
                The manual for them can be found <a href="/sources/DS3802-THC20F17BD-V40-EN-US.pdf">here</a>.
                You can order blank smartcards <a href='https://aliexpress.com/item/1005002117450387.html' target="_blank" rel="noreferrer">here</a> or <a href="https://t.me/emfaz" target="_blank" rel="noreferrer">here</a>.
                When ordering from other sellers, make sure to verify the ATR of the purchased smartcards.
                Since the manufacturer stopped producing THC20F17 cards in 2024 and they are now only available as stockpiles,
                starting from TVCAS4 version, support for <b>THC20F150A</b> smartcards has been added.
                This is a newer development in the same family, differing only by having a larger amount of FLASH memory.
            </p>

            <center>
                <img src='/sources/thc20f17bd-v40.jpg' alt='' width='60%' /><br />
                Smartcards in different form factors
            </center>

            <Alert severity="warning" style={{margin:"20px 0 20px"}}>
                <AlertTitle>ATR of blank smartcards</AlertTitle>
                <div>THC20F17BD-v40 = <span style={{color:"green"}}>3B1F96380503200617020440100007089000</span></div>
                <div>THC20F150A = <span style={{color:"green"}}>3B1F963C0503200601500110100007089000</span></div>
            </Alert>

            <h3>Smartcard Programming</h3>
            <p>
                To program supported smartcards, a standard Phoenix programmer, such as <b>TBS3102</b>, is required.
                You can purchase it <a href='https://aliexpress.com/item/32608636124.html' target='_blank' rel='noreferrer'>here</a>
                or make one yourself using <ImageModal url="/sources/phoenix-scheme.jpg">this</ImageModal> scheme.
                Programmer settings: <b>5V / 3.57 Mhz</b>.
            </p>

            <center><h3>The process is shown in the video</h3>
            <YouTube videoId={"y1A9-OJKo3w"} opts={{ width: '70%'}} />
            </center>

            <Alert severity="info" style={{margin:"20px 0 20px"}}>
                <AlertTitle>Update</AlertTitle>
                The ability to launch the loader without parameters has been made possible. To do this, download it (starting from version 4.005)
                from the footer of the control panel. Your parameters: <b>IP:port</b>, <b>API key</b> are automatically embedded into the binary file,
                which <ImageModal url="/sources/uploader_wo_params.jpg">allows for this feature</ImageModal>. Please note that you should not share your <b>uploader.exe</b> file 
                with anyone to prevent access to your server. The option with parameters is still available.
            </Alert>

            <hr />

            <h3>Changing ECM/EMM Keys on Smartcards</h3>
            <p>
                Starting from version 4.018, the ability to change ECM/EMM keys on your smartcards has been made available, provided they were flashed 
                with a loader version of at least 2.010. This procedure may be required, for example, if your keys have been compromised.
                Changing keys is a cautious process, so it is advisable to work out the steps on a test system first:
                <ul>
                    <li>Select a time period (5-7 days) when subscriber package changes are relatively calm (because subscriptions will be slow, only through <b>ECMGIFT</b>)</li>
                    <li>Run your TVCAS4 server in "master" mode: <code>./server_v.4.xxx master</code><br />
                        <i>In this mode, the control panel menu will turn <b style={{color:"red"}}>red</b>,
                        signaling caution in using this mode!</i></li>
                    <li>Go to the configuration section and change your ECM key to another one. Click the "Save" button.</li>
                    <li>Leave the server running in "master" mode for 5-7 days, so that the physical smartcards of your subscribers accept the new ECM key.</li>
                    <li>Once most of the smartcards have updated the ECM key, you can switch your server back to normal operation mode,
                        and leave the created <b>ECMG with the old key</b> on one of your channels:
                        this way, you will be able to update smartcards that were inactive during the main update period in the future.
                    </li>
                </ul>
                <b>Attention!</b> The above describes the ECM key update process. To update all ECM/EMM keys, please consult the author. This is a paid service.
            </p>

            <hr />

            <h3>Virtual Smartcards for ARM64 Platform</h3>
            <p>
                A virtual smartcard is a program (executable file) that runs on a Set-Top-Box based on ARM64 processors.
                The program functions similarly to a virtual CAM (working via <b>newcamd</b>), but it does not require an internet connection to operate.
                Subscriptions are received via EMM. It completely mimics the operation of a physical TVCAS4 smartcard.
                You can <ImageModal url="/sources/smartcard_arm64.jpg">download</ImageModal> it in the smartcard subscription editing window of your control panel.
                After copying to the STB, don't forget to set execution permissions (0777).
            </p>
            <p>
                The program <ImageModal url="/sources/raspberry_arm64.jpg">establishes a connection</ImageModal> via the <b>newcamd</b> protocol on port <code>:12345</code>.
                The login/password for connection matches your smartcard number (10 characters).<br />
                Note that for security reasons, the virtual smartcard is initially downloaded with subscriptions disabled!
                To activate it, wait for your EMM (or ECMGIFT) with the subscription.
                To <ImageModal url="/sources/virtual_smartcard_log.jpg">counteract sharing</ImageModal> of this virtual smartcard, a limit on requests to it (anti-sharing) has been implemented.
            </p>

            <hr />

            <h3>TVCAS4 Smartcard Eraser</h3>
            <p>
                The <ImageModal url="/sources/eraser.jpg">Eraser</ImageModal> is a device, resembling a card reader, that allows a recorded smartcard to be restored to its factory firmware.
                Unlike software erasure (through the TVCAS4 control panel), the physical eraser can restore the smartcard even if the protection flag is enabled.
                The device is available for order only for clients with a purchased license.
            </p>

            <Dalee url="pairing" />

        </>
    )
}
