import React, { useState } from 'react';
import { Modal } from '@mui/material';

const ImageModal = ({ url, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <span id={Math.random()} style={{fontWeight:"bold", color:"blue", borderBottom:"1px dashed blue", cursor:"pointer"}} onClick={handleOpen}>{children}</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
        }}>
          <img src={url} alt={children} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
      </Modal>
    </>
  );
};

export default ImageModal;