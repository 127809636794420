import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import 'App.css'
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';
import { Grid } from '@mui/material';

export default function Interface(){




    return (
        <><h1><ElectricBoltIcon /> Веб-интерфейс</h1>

            <p>
                Веб-интерфейс для управления сервером TVCAS4 состоит из 6(шести) основных разделов:
            </p>

            <ul>
                <li><b>Smartcards</b> - главная страница интерфейса, выводит список и функции управления смарткартами</li>
                <li><b>Packages</b> - список пакетов программ и управление над ними</li>
                <li><b>ECMG</b> - ECM генераторы</li>
                <li><b>EMMG</b> - EMM генераторы</li>
                <li><b>Logs</b> - логирование системы</li>
                <li><b>Config</b> - настройки TVCAS4</li>
            </ul>

            <center><img src="/sources/ui-smartcards.jpg" alt="Smartcards TVCAS4" width="90%" /></center>

            <p>
                Основная страница <b>Smartcards</b> в меню интерфейса сделана максимально интуитивно понятной.
                Стоит остановиться только на некоторых моментах.
                Так, для оперативного управления доступом к телепрограммам имеются две функции
                    «Deactivate» - деактивирует время подписки (немедленно отключает доступ у карты)
                    и «Activate» - при нажатии на её происходит продление подписки до конца месяца.
                Причём, если она нажата до 15 числа <u>нынешнего</u> месяца, то выбранная смарткарта активируется до конца <u>нынешнего</u> месяца,
                в противном случае, если после 15-го, то время подписка изменится до конца <u>следующего</u> месяца (оплатные периоды).
            </p>
            <p>
                В опциях каждой смарткарты имеются: редактирование её подписок, прошивка, очистка до заводской прошивки, стирание счётчика.
                Последние две функции доступны только после приобретения лицензии TVCAS4.
            </p>

            <hr />

            <p>
                Раздел <b>Packages</b> отвечает за пакеты телепрограмм.
                После установки и начальной настройки системы следущий шаг - создание пакетов.
                Большинству ТВ-операторов достаточно 3-5 пакетов телепрограмм.
                Но система позволяет иметь их до 32 штук.
                При клике по цифре в колонке <b>Clients</b> происходит подсчёт количества абонентов, имеющих доступ к данному пакету.
                Параметры <b>Access criteria</b> необходимо устанавливать <ImageModal url="/sources/ac-astra.jpg">свойствах телеканала</ImageModal> на скремблере.
                Если канал принадлежит нескольким пакетам программ, то в <b>Access criteria</b> канала пишется <ImageModal url="/sources/ac-astra2.jpg">результат</ImageModal> суммы всех необходимых пакетов.
            </p>

            <center><img src="/sources/ui-packages.jpg" alt="Packages TVCAS4" width="80%" /></center>

            <hr />

            <p>
                TVCAS4 позволяет создать Вам столько много генераторов, сколько есть свободных портов.
                Их настройка максимально упрощена и не нуждается в пояснениях.
                Единственное пояснение про чекбокс <b>GIFT</b> у ECMG. Данная опция рассылает подписки в ECM-пакете.
                Её рекомендуется использовать на готовых рабочих системах, чтобы предотвратить отключение EMM на стороне абонента.
            </p>

            <Grid container>
                <Grid item xs={6}>
                   <center><img src="/sources/ecmg.jpg" alt="ECMG TVCAS4" height="300px" /></center>
                </Grid>
                <Grid item xs={6}>
                    <center><img src="/sources/emmg.jpg" alt="ECMG TVCAS4" height="300px" /></center>
                </Grid>    
            </Grid>

            <hr />

            <Dalee url="smartcards" />

        </>
    )
}