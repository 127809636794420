import React, { useState } from 'react'
// import { Alert, AlertTitle } from '@mui/material';
import 'App.css'
import { Alert, AlertTitle, Button, FormControlLabel, Grid } from "@mui/material";
import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export default function License(){ 

    const [uid, setUid] = useState("")
    const [email, setEmail] = useState("")
    const [promoVisible, setPromoVisible] = useState(false)
    const [promoText, setPromoText] = useState("")


  

    function isEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    return (
        <>
          <h1>Условия лицензирования</h1>

          <p>
            Программное обеспечение TVCAS4 (ПО), предназначенное для кодирования телеканалов в сети ТВ-оператора, поставляется по принципу «как есть» («as is»).
            Вы можете самостоятельно тестировать ПО так долго, как вам это необходимо,
            а автор гарантирует, что работоспособность не изменится с течением времени.
            Также, юридически гарантируется, что никакие личные данные (ключи, данные абонентов и т.п.) из ПО не отправляются на внешние сервера.
            Однако, автор не несёт ответственности, если использование ПО принесёт какой-либо материальный или имиджевый ущерб
            при несоблюдении <Link to="/features">рекомендаций</Link> и условий безопасности.
          </p>

          <p>
            <b>Ограничения (без приобретения лицензии):</b>
          </p>
          <ul>
              <li>недоступна функция стирания смарткарты и отключения счётчика запросов на ней</li>
              <li>недоступна установка собственного <code>trademark</code> на прошитых картах (иначе будет 'TVCAS')</li>
              <li>лицензионное ожидание от 20 до 30 секунд при прошивке смарткарт в <b>uploader.exe</b></li>
              <li>недоступен регулятор скорости EMM-генератора</li>
              <li>недоступна функция радара смарткарты</li>
              <li>всплывающее сообщение у абонента: «<i style={{color:"green", fontWeight:"bold"}}>Your operator use free version TVCAS4</i>»</li>
              <li>недоступны для скачивания виртуальные смарткарты для платформ AMD64 и ARM64</li>
          </ul>

          <p>
            Для отключения ограничений, необходимо приобрести лицензию, представляющую из себя строку из 32 символов,
            т.н. <b>Activation ID (AID)</b>.
            Лицензия формируется как производная от номера <b>UID</b>, указанного в конфигурации ПО.
          </p>

          <center><img style={{margin:"30px 0", border:"1px solid #eee", boxShadow:"0px -5px 10px 0px rgba(0, 0, 0, 0.5)"}} src="/sources/aid.jpg" alt="uid-aid" width="80%" /></center>

          <p>
            Стоимость лицензии для одного UID составляет <b>1300,00 белорусских рублей</b>, что приблизительно равно <b>$399.00</b> по курсу Нацбанка Республики Беларусь.
            Лицензия для вашего UID будет отправлена на указанный EMAIL сразу же после подтверждения оплаты.
            Она приобретается навсегда и не может быть отозвана или возвращена обратно. Также она действует и на будущие обновления и исправления TVCAS4.
            Если вдруг вы потеряли лицензию или по какой-то причине после апгрейда сервера у вас изменился UID,
            сообщите по почте <b>admin@tvcas.com</b> или в телеграмм <a href="https://t.me/unidiag" rel="noreferrer" target="_blank">@unidiag</a>.
            Мы постараемся оперативно помочь.
          </p>

          <hr />

          <Grid container>


          <Grid item xs={4}>
            <Alert style={{marginBottom:"20px"}}>
                Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально принимает от клиента данные его платежной карты (номер карты, имя держателя, дату окончания действия и контрольный номер банковской карточки CVC/CVC2).
                Обращаем Ваше внимание, что после совершения оплаты с использованием банковской карты необходимо сохранять полученные на электронный адрес карт-чеки (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в случае возникновения спорных ситуаций).
            </Alert>
          </Grid>


            <Grid item xs={4}>

          <center>


            <b>Для получения лицензии, заполните<br />необходимые поля и оплатите заказ</b>

            <div style={{marginTop:"20px"}}><TextField
              onChange={(e) => {
                var _this = e.target.style
                setUid(e.target.value)
                if (e.target.value.length !== 32){
                  _this.backgroundColor = "#fa9d9d"
                }else{
                  _this.backgroundColor = ""
                }
              }}
              style={{width:"300px"}} label="Введите свой UID" name="uid" />
            </div>
            <div style={{marginTop:"10px"}}><TextField
              onChange={(e) => {
                var _this = e.target.style
                setEmail(e.target.value)
                if (!isEmail(email)){
                  _this.backgroundColor = "#fa9d9d"
                }else{
                  _this.backgroundColor = ""
                }
              }}
              style={{width:"300px"}} type="email" label="Введите свой Email" maxRows={50} name="email" />
            </div>

            <div style={{marginTop:"10px"}}>
              <FormControlLabel control={<Checkbox onClick={e => setPromoVisible(e.target.checked)} />} label="У меня есть промокод" />
              {
                promoVisible &&
                <TextField style={{width:"300px"}} label="Введите промокод" maxRows={10} name="promo"
                  onChange={ e => setPromoText(e.target.value) }
                />
              }
            </div>



            <Button
                style={{marginTop:"20px"}}
                variant="contained"
                startIcon={<PaymentIcon />}
                onClick={()=> {
                    if(uid.length === 32 && isEmail(email)){
                      var redir = "/payment.php?uid="+uid+"&email="+email
                      if(promoVisible){
                        redir += "&promo="+promoText
                      }

                      window.location.href = redir
                      //alert("Payment is temporarily disabled.\nWe are waiting for the banking service to be connected.")
                    }else{
                      alert("Please fill out the form details correctly!")
                    }
                }}>
                Перейти к оплате
            </Button>
          </center>

          </Grid>

          <Grid item xs={4}>
            <Alert severity={"warning"}>
              <AlertTitle>Правила возврата средств</AlertTitle>
              <div>При ошибочном списании с банковской платежной карты через систему WEBPAY возврат денежных средств осуществляется на ту же карточку, с которой была произведена оплата.
              Для возврата денежных средств на банковскую платежную карточку необходимо связаться по контактным данным, указанным на интернет-ресурсе. По операциям, проведенным с ошибками, необходимо обратиться с приложением чеков/квитанций, подтверждающих ошибочное списание.</div>

              <div style={{marginTop:"5px"}}>Срок возврата денежных средств на карточку, как правило, составляет 7 (семь) календарных дней и зависит от банка-эмитента, выпустившего карточку. Сумма возврата будет равняться сумме покупки.</div>
            </Alert>
          </Grid>

          </Grid>
        </>
    )
}