import React from 'react';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle, Typography } from '@mui/material';
import 'App.css';
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';

export default function Features() {
    const ans = '{"serial_no":"2100000000","descr":"Jack London","access_criteria":"00000001","pair":"0","start":"1234567890","finish":"1234567890"}';

    return (
        <>
            <h1><ElectricBoltIcon /> TVCAS4 Features</h1>

            <ul>
                <li>Maximum guaranteed subscribers up to 1,000,000 (not tested beyond this)</li>
                <li>Number of encrypted channels <b>255 for one ECMG</b></li>
                <li>The number of ECMG and EMMG is limited by the availability of your ports in the operating system</li>
                <li>Use of smart cards <b>THC20F17BD</b> or more modern counterparts <b>THC20F150A</b></li>
                <li>Maximum number of implemented program packages (classes) - 31</li>
                <li>Integration with billing via CSV export/import files</li>
                <li>Simple API for managing smart card subscriptions</li>
                <li><b>ECMGIFT</b> feature embedding subscriptions into ECM packages</li>
                <li>Smart card blocking function in case of unusual usage attempts</li>
                <li><ImageModal url="/sources/VirtualCAM.svg">Virtual CAM</ImageModal> - working with TVCAS4 without smart cards</li>
                <li>Smart card radar for counteracting key sharing</li>
                <li>Parental control PIN code and the ability to remotely reset it (default <b>1234</b>)</li>
                <li>Sending pop-up messages (SMS) to the subscriber's screen (up to 47 characters in Latin)</li>
                <li><ImageModal url="/sources/smartcard_arm64.jpg">Virtual smart cards</ImageModal> for ARM64 platform</li>
                <li>Priority EMM mode in the generator (relevant when using more than 50 thousand smartcards)</li>
            </ul>

            <Alert severity="info">
                <AlertTitle>API Request Example:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>serial_no</b>=2100000000&<b>set[descr]</b>=Jack%20London&<b>set[pair]</b>=0&<b>set[start]</b>=1234567890&<b>set[finish]</b>=1234567890&<b>set[access_criteria]</b>=00000001
                </code>
                <AlertTitle style={{marginTop:"10px"}}>Newcamd Information:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>newcamd</b>= connections || banned || unbann || smartcards
                </code>
                <AlertTitle style={{marginTop:"10px"}}>Send SMS Immediately:</AlertTitle>
                <code>
                    http://tvcas.local/api.php?<b>api_key</b>=myrand16letters&<b>serial_no</b>=2100000000&<b>sms</b>=This%20is%20my%20important%20info%20for%20client!
                </code>
            </Alert>

            <p>The <b>set[x]</b> parameters are optional. If they are completely absent, the card information will be returned in json format. If one or more <b>set[x]</b> parameters were passed, these fields will be updated in the database, and then the updated card information will be returned. Thus, the json response will contain the updated information as requested.</p>

            <p>
                Please note that SMS sent via API is sent only once (usually within 10 seconds) and is more for testing the messaging system. To add a message to the EMM rotation, use the TVCAS4 control panel. Additionally, note the friendly-CAM for the viewer: the pop-up message cannot be shown again during the CAM session. However, the author has encountered cases where this restriction is absent in some STBs.
            </p>

            <Alert severity="warning" style={{margin:"30px 0"}}>
                <AlertTitle>Use an API key consisting of 16 characters (letters and digits)!</AlertTitle>
            </Alert>

            <Alert severity="info" style={{marginBottom: "20px"}}>
                <AlertTitle>API Response Example:</AlertTitle>
                <code>{ans}</code>
            </Alert>

            <Alert severity="error">
                <AlertTitle>API Response Errors</AlertTitle>
                <span className="console">
                    <b>NOT_VALID_API_KEY</b> — Invalid API key. Check it against the value in CONFIG<br />
                    <b>SMARTCARD_NOT_FOUND</b> — Smart card not found in the TVCAS database<br />
                    <b>UNKNOWN_SET_PARAMETER</b> — One or more parameters are unknown to the system<br />
                    <b>ACCESS_CRITERIA_ERROR</b> — The <u>access_criteria</u> parameter is not within the range <code>00000000-FFFFFFFF</code><br />
                    <b>PAIR_ERROR</b> — Error setting the PAIR flag (possible values 0 or 1)<br />
                    <b>START_ERROR</b> or <b>FINISH_ERROR</b> — Incorrect Unix time setting (10 digits)
                </span>
            </Alert>

            <h1><ElectricBoltIcon /> Using MySQL</h1>
            <p>By default, TVCAS4 uses an SQLite3 database, running on a single file in the working directory <b>./db.sqlite3</b>. If using a large number of smart cards (more than 1000), it is recommended to use MySQL. Support is implemented starting from version 4.025.</p>
            <p><b>1. Install MySQL (MariaDB) on the server:</b><br />
    <code>apt install mariadb-server<br />
    systemctl start mariadb<br />
    mysql_secure_installation </code> #create root password, etc.
            </p>

            <p><b>2. Create a database and user for TVCAS4:</b><br />
                <code>CREATE USER 'tvcas4user'@'localhost' IDENTIFIED BY 'tvcas4pass';<br />
                CREATE DATABASE tvcas4db CHARACTER SET utf8 COLLATE utf8_general_ci;<br />
                GRANT ALL PRIVILEGES ON tvcas4db.* TO 'tvcas4user'@'localhost';<br />
                FLUSH PRIVILEGES;<br />
                SHOW GRANTS FOR 'tvcas4user'@'localhost';</code>
            </p>

            <p><b>3. Export the `smartcards.csv` file with your smart cards via the corresponding function in the TVCAS4 control panel.</b></p>

            <p><b>4. Create a Data Source Name file `./mysql.dsn` in the working directory with the following content:</b><br />
                <code>tvcas4user:tvcas4pass@tcp(127.0.0.1:3306)/tvcas4db</code>
            </p>

            <p><b>5. Restart the TVCAS4 server:</b><br />
                <code>systemctl restart tvcas4</code>
            </p>

            <p><b>6. Go to the TVCAS4 control panel, import `smartcards.csv`, create program packages and generators.</b></p>

            <Alert severity="warning" style={{margin:"30px 0"}}>
                <AlertTitle>To use MySQL, ensure that there is a file in the root <code>./mysql.dsn</code> with the database connection parameters.</AlertTitle>
                If it is missing or the connection to MySQL is incorrect, <b>SQLite3</b> will be used by default.
            </Alert>

            <Alert severity="success" style={{margin:"30px 0"}}>
                If you are using <b>systemd</b> for auto-starting the TVCAS4 server, add the following text to the service file:
                <div style={{backgroundColor:"#555", margin:"10px 20px", color:"#ccc", padding:"10px 20px", fontFamily:"Consolas"}}>[Unit]<br />
Description=tvcas4<br />
After=network.target <b style={{color:"#ff87a5"}}>mariadb.service</b><br />...</div>
            </Alert>

            <h1><ElectricBoltIcon /> Recommendations</h1>

            <Typography variant="h6">Technical Requirements</Typography>
            <ul>
                <li><b>Server</b>: 1 CPU core, 1 GB RAM, 100 MB disk space, Debian 11 or newer</li>
                <li><b>Uploader</b>: Windows 10 or newer</li>
                <li><b>Scrambler</b>: <a href="https://cdn.cesbo.com/astra" target="_blank" rel="noreferrer">Latest Cesbo Astra</a> compatible with TVCAS4</li>
            </ul>

            <Typography variant="h6" style={{marginTop:"50px"}}>Security</Typography>

<p>Using this CAS for television services implies that you understand all the risks and potential levels of system security leakage.
    From a software code perspective, the author guarantees that such leakage cannot be achieved through external influence on the system.
    However, despite the fact that the keys are stored encrypted in the database, caution should be taken regarding the human factor, or so-called social engineering (ESET), through which your ECM/EMM keys could be compromised.
</p>
<ul style={{marginTop:"10px"}}>
    <li>It is not recommended to open ports with access to the global network! Ideally, the internet should be disabled on the server where the TVCAS4 system is installed.</li>
    <li>Change the passwords for the <b>admin</b> and <b>oper</b> users immediately after the first launch</li>
    <li>Use <b>admin</b> access only for the initial system setup and when programming/erasing smart cards</li>
    <li>Limit system access to a small circle of individuals. If you don't have a billing system, you can grant operator access through the <b>oper</b> user, 
    but the best solution is to connect the system to external programs through API exchange</li>
</ul>

<Typography variant="h6" style={{marginTop:"50px"}}>Smart Card Firmware/Erasure</Typography>
<p>
    The process of flashing and erasing smart cards is not complicated, but one negative property of <b>Windows</b> should be considered.
    If a console application is running in the background, its operation may slow down as resources are redirected to other processes.
    If you have sent a command to flash/erase a smart card and the counter in <b>uploader</b> "freezes", just refocus on the Windows console
    and press any key (e.g., "space") — the flashing/erasing process will continue.
</p>

<Typography variant="h6" style={{marginTop:"50px"}}>Bug and Fault Detection</Typography>
<p>
    Typically, if you follow the installation and operation recommendations of TVCAS4, no issues should arise during operation.
    However, operators often start combining different system usage scenarios, and since these cases were not foreseen by the author,
    incorrect behavior is quite possible. You can report such bugs and issues in the public group <a href='https://t.me/tvcas' target='_blank' rel='noreferrer'>@tvcas</a>.
    If the issue is known, other participants may help resolve it. Otherwise, the author will release an update with a fix.
    Priority for updates is given to users with a purchased license.
</p>
<p>
    <span style={{color:"red"}}>Attention!</span> When sending a bug report, please attach log messages and other materials that can help better understand the issue.
    Messages like: "My TVCAS4 is not working (or won't start)" will be ignored because they do not provide any additional information.
    If the issue concerns the operation of EMM generators, first make sure the scrambler is adding these EMMs to your channel stream.
    You can check this using the console utility <ImageModal url="/sources/emmdetector.jpg">emmdetector</ImageModal>:
</p>
<Alert>
    <code>
    wget https://tvcas.com/sources/emmdetector<br />
    chmod 766 ./emmdetector<br />
    ./emmdetector
    </code>
</Alert>

<p>
    Additionally, to monitor traffic (ECM/EMM) between the scrambler and TVCAS4, we recommend using <a href='https://github.com/unidiag/cascap' target='_blank' rel='noreferrer'>CASCAP</a>.
</p>

        <Dalee url="diagram" />

        </>
    );
}
