import { Box } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useEffect, useState } from 'react'
import {isEn} from './Functions'


export default function Footer(){
    //const [address, setAddress] = useState("ул.П.Бровки 4Б")
    //const [phone, setPhone] = useState("+375 (29) 741-40-36")
    const [address, setAddress] = useState("Browki st.4A-204")
    const [phone, setPhone] = useState("+375 (29) 741-40-36")

    useEffect(function(){
        fetch("/ext.php?op=ip_code")
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // или response.text() если ожидается другой тип данных
        })
        .then(data => {
            if (data === "BY"){
                setAddress("пер.Старосельский, д.9")
                setPhone("+375 (29) 715-36-16")
            }
        })
        .catch(error => {
            // Обработка ошибок
            console.error('There was a problem with the fetch operation:', error);
        });
    }, [])


    return (
        <Box>
            {!isEn() ? (<div style={{margin:"20px 20px 0"}}>
                <div style={{display: "flex", justifyContent: "space-between"}} ><span><b>Автор</b>: ИП Тумашевский Виталий Алексеевич</span><span><b>УНП 391327079</b>, свидетельство от 01.06.2023г. выдано Администрацией Первомайского р-на г.Витебска</span></div>
                <div style={{display: "flex", justifyContent: "space-between"}} ><span><b>Юридический адрес</b>: Республика Беларусь, г.Витебск, {address}</span><span><b>Почтовый адрес</b>: 210038, Республика Беларусь, г.Витебск, а/я 5</span></div>
                <div style={{display: "flex", justifyContent: "space-between"}} ><span><b>Телефон</b>: {phone}</span><span>Номер в Торговом реестре <b>557682</b> от 01.04.2024г.</span></div>
                <div style={{display: "flex", justifyContent: "space-between"}} ><span><b>Telegram</b>: <a href="https://t.me/unidiag" target="_blank" rel="noreferrer">@unidiag</a></span><span><AccessTimeIcon style={{marginLeft:"20px", position:"relative", top:"5px"}} /> ПН-ПТ c 10-00 до 20-00</span></div>
            </div>) : (<div style={{margin:"20px 20px 0"}}>
                <div style={{display: "flex", justifyContent: "space-between"}} >
                    <span><b>Author</b>: IE Vitali Tumasheuski</span>
                    <span><b>TIN 391327079</b>, certificate issued on 01.06.2023 by the Administration of the Pervomaisky district of Vitebsk</span>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}} >
                    <span><b>Legal address</b>: Belarus, Vitebsk, {address}</span>
                    <span><b>Postal address</b>: 210038, Belarus, Vitebsk, P.O. Box 5</span>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}} >
                    <span><b>Phone</b>: {phone}</span>
                    <span>Number in the Trade Register <b>557682</b> as of 01.04.2024</span>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}} >
                    <span><b>Telegram</b>: <a href="https://t.me/unidiag" target="_blank" rel="noreferrer">@unidiag</a></span>
                    <span><AccessTimeIcon style={{marginLeft:"20px", position:"relative", top:"5px"}} /> MON-FRI from 10:00 to 20:00</span>
                </div>
            </div>)
}
            <div style={{marginTop:"20px"}}><img src='/sources/line_color_webpay2.png' alt='payment methods' width='100%' /></div>
        </Box>
    )
}