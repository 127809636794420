import React, { useEffect, useState } from 'react';
import { isEn } from './Functions';
import Flag from 'react-world-flags';

const GoogleTranslate = () => {

    const [load, setLoad] = useState(true)

    useEffect(() => {
        const addGoogleTranslateScript = () => {
            const script = document.createElement('script');
            script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            script.async = true;
            setLoad(false)
            document.body.appendChild(script);
        };

        // const translateToEnglish = () => {
        //     const select = document.querySelector('select.goog-te-combo');
        //     if (select) {
        //       select.value = 'en'; // Устанавливаем язык "en"
        //       select.dispatchEvent(new Event('change')); // Триггерим изменение языка
        //     }
        //   };
    
        // setTimeout(translateToEnglish, 1000);
    
        if ((!window.google || !window.google.translate) && load) {
            addGoogleTranslateScript();
        } else {
            setTimeout(function(){
                new window.google.translate.TranslateElement({
                    pageLanguage: 'ru',
                    includedLanguages: 'en,es,fr,de,ar,zh-CN,zh-TW,bg,ro,sl,sk,hu,it,pt,nl,pl,sr,tr,uz,mn,ka,hi,sq'
                }, 'google_translate_element')
            }, 500)
        }
    }, [load]);



    return (
        //<div id="google_translate_element"></div>
        <div className='language'>
            {
                isEn() ? 
                (<a href="/"><Flag code="RU" style={{ width: 30, margin: "5px 10px 0"  }}  /></a>) :
                (<a href="/en"><Flag code="US" style={{ width: 30, margin: "5px 10px 0" }} /></a>)
            }
        </div>
    )
};

export default GoogleTranslate;