import React, { useState } from 'react'
// import { Alert, AlertTitle } from '@mui/material';
import 'App.css'
import { Alert, AlertTitle, Button, FormControlLabel, Grid } from "@mui/material";
import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export default function License(){ 

    const [uid, setUid] = useState("")
    const [email, setEmail] = useState("")
    const [promoVisible, setPromoVisible] = useState(false)
    const [promoText, setPromoText] = useState("")


  

    function isEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    return (
        <>
          <h1>Licensing Terms</h1>

          <p>
            The TVCAS4 software (the Software), designed for encoding TV channels in a TV operator's network, is supplied "as is."
            You can test the Software for as long as you need,
            and the author guarantees that its functionality will not change over time.
            It is also legally guaranteed that no personal data (keys, subscriber data, etc.) is sent from the Software to external servers.
            However, the author is not responsible if using the Software causes any material or reputational damage
            due to non-compliance with <Link to="/features">recommendations</Link> and security conditions.
          </p>

          <p>
            <b>Restrictions (without purchasing a license):</b>
          </p>
          <ul>
              <li>smartcard erasure function and disabling the request counter on it are unavailable</li>
              <li>setting your own <code>trademark</code> on flashed cards is unavailable (otherwise it will be 'TVCAS')</li>
              <li>license wait time from 20 to 30 seconds when flashing smartcards in <b>uploader.exe</b></li>
              <li>EMM generator speed controller not available</li>
              <li>the smartcard radar function is unavailable</li>
              <li>popup message for subscribers: "<i style={{color:"green", fontWeight:"bold"}}>Your operator uses a free version of TVCAS4</i>"</li>
              <li>virtual smartcards for AMD64 and ARM64 platforms are not available for download</li>
          </ul>

          <p>
            To remove these restrictions, you need to purchase a license, which is a 32-character string,
            the so-called <b>Activation ID (AID)</b>.
            The license is generated as a derivative of the <b>UID</b> number specified in the Software configuration.
          </p>

          <center><img style={{margin:"30px 0", border:"1px solid #eee", boxShadow:"0px -5px 10px 0px rgba(0, 0, 0, 0.5)"}} src="/sources/aid.jpg" alt="uid-aid" width="80%" /></center>

          <p>
            The cost of a license for one UID is <b>1300.00 Belarusian rubles</b>, which is approximately equal to <b>$399.00</b> at the exchange rate of the National Bank of the Republic of Belarus.
            The license for your UID will be sent to the provided EMAIL immediately after payment confirmation.
            It is purchased for life and cannot be revoked or returned. It also applies to future updates and fixes for TVCAS4.
            If you lose your license or for some reason your UID changes after a server upgrade,
            please contact us via email at <b>admin@tvcas.com</b> or on Telegram at <a href="https://t.me/unidiag" rel="noreferrer" target="_blank">@unidiag</a>.
            We will try to assist you promptly.
          </p>

          <hr />

          <Grid container>


          <Grid item xs={4}>
            <Alert style={{marginBottom:"20px"}}>
                The secure WEBPAY server establishes an encrypted connection via the TLS protocol and confidentially receives the client's payment card data (card number, cardholder's name, expiration date, and CVC/CVC2 code).
                Please note that after making a payment using a bank card, it is necessary to save the receipts (payment confirmations) received via email for reconciliation with your card account statement (to confirm transactions in case of disputes).
            </Alert>
          </Grid>


            <Grid item xs={4}>

          <center>


            <b>To obtain a license, fill in the required fields and pay for the order</b>

            <div style={{marginTop:"20px"}}><TextField
              onChange={(e) => {
                var _this = e.target.style
                setUid(e.target.value)
                if (e.target.value.length !== 32){
                  _this.backgroundColor = "#fa9d9d"
                }else{
                  _this.backgroundColor = ""
                }
              }}
              style={{width:"300px"}} label="Enter your UID" name="uid" />
            </div>
            <div style={{marginTop:"10px"}}><TextField
              onChange={(e) => {
                var _this = e.target.style
                setEmail(e.target.value)
                if (!isEmail(email)){
                  _this.backgroundColor = "#fa9d9d"
                }else{
                  _this.backgroundColor = ""
                }
              }}
              style={{width:"300px"}} type="email" label="Enter your Email" maxRows={50} name="email" />
            </div>

            <div style={{marginTop:"10px"}}>
              <FormControlLabel control={<Checkbox onClick={e => setPromoVisible(e.target.checked)} />} label="I have a promo code" />
              {
                promoVisible &&
                <TextField style={{width:"300px"}} label="Enter promo code" maxRows={10} name="promo"
                  onChange={ e => setPromoText(e.target.value) }
                />
              }
            </div>



            <Button
                style={{marginTop:"20px"}}
                variant="contained"
                startIcon={<PaymentIcon />}
                onClick={()=> {
                    if(uid.length === 32 && isEmail(email)){
                      var redir = "/payment.php?uid="+uid+"&email="+email
                      if(promoVisible){
                        redir += "&promo="+promoText
                      }

                      window.location.href = redir
                      //alert("Payment is temporarily disabled.\nWe are waiting for the banking service to be connected.")
                    }else{
                      alert("Please fill out the form details correctly!")
                    }
                }}>
                Proceed to Payment
            </Button>
          </center>

          </Grid>

          <Grid item xs={4}>
            <Alert severity={"warning"}>
              <AlertTitle>Refund Policy</AlertTitle>
              <div>In case of erroneous charges through the WEBPAY system, the refund will be made to the same card used for payment.
              To receive a refund, please contact the information provided on the website. For transactions conducted with errors, it is necessary to provide receipts/invoices confirming the erroneous charge.</div>

              <div style={{marginTop:"5px"}}>The refund processing time is generally 7 (seven) calendar days and depends on the issuing bank. The refund amount will equal the purchase amount.</div>
            </Alert>
          </Grid>

          </Grid>
        </>
    )
}
