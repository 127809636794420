import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import GoogleTranslate from './GoogleTranslate';
import { useEffect } from 'react';
import {isEn} from 'components/Functions'




function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [upd, setUpd] = React.useState(Math.random());
  const [pages, setPages] = React.useState([
    ["/",             'Главная'],
    ["features",      'Возможности'],
    ["diagram",       'Как работает'],
    ["install",       'Установка'],
    ["interface",     'Интерфейс'],
    ["smartcards",    'Смарткарты'],
    ["pairing",       'Сопряжение'],
    ["license",       'Лицензия']
  ]);
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setUpd(Math.random())
    setAnchorElNav(null);
  };

  useEffect(function(){

    if (isEn()) {
      setPages([
        ["/en/",             'Home'],
        ["/en/features",      'Features'],
        ["/en/diagram",       'How it work'],
        ["/en/install",       'Install'],
        ["/en/interface",     'Interface'],
        ["/en/smartcards",    'Smartcards'],
        ["/en/pairing",       'Pairing'],
        ["/en/license",       'License']
      ])

    }

    var url = window.location.href
    for(var i=0; i<pages.length; i++){
      if(url.indexOf(pages[i][0]) > 0){
        document.title = pages[i][1] + " | TVCAS4"
      }
    }
    // eslint-disable-next-line
  }, [upd])




  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>







          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 3,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '0rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/sources/menu_logo.png' height="50px" alt='' />
          </Typography> 

          











          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((el) => (
                <NavLink key={el[0]} to={el[0]} className="Navlink2">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{el[1]}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>



          






          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/sources/menu_logo.png' height="50px" alt='' />
          </Typography>





    




          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((el) => (
              <NavLink key={el[0]} to={el[0]} className="Navlink">
              <Button
                key={el[0]}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  {el[1]}
              </Button></NavLink>
            ))}
          </Box>

          <GoogleTranslate />

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
