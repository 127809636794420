import React from "react";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Alert, AlertTitle, Grid } from "@mui/material";
import Dalee from "components/DaleeBtn";
import PairCalc from "components/PairCalc";
import ImageModal from "components/ImageModal";

export default function Pairing(){

    const golang = `
    resultHash := OneWayHash([]byte{0x20,0x15,0xA9,0x8C,0x06,0xF8,0xE3,0x58}, []byte{0xDA,0xAF,0xA9,0xD7,0x95,0xA4,0x79,0x2E})
    // resultHash = []byte{0xB6,0x95,0x79,0x58,0xEE,0xEA,0xC5,0x07}

    func reverseArray(arr []byte) []byte {
        length := len(arr)
        for i := 0; i < length/2; i++ {
            j := length - i - 1
            arr[i], arr[j] = arr[j], arr[i]
        }
        return arr
    }

    func OneWayHash(pairkey []byte, vectorTable []byte) []byte {
        hash1 := binary.LittleEndian.Uint32(reverseArray(pairkey[:4]))
        hash2 := binary.LittleEndian.Uint32(reverseArray(pairkey[4:]))
        result := make([]byte, 8)
        for i := 0; i < 8; i++ {
            hash1 ^= (hash2 << 2) + uint32(vectorTable[i])
            hash2 ^= (hash1 << 2) + uint32(vectorTable[i])
            hash1 += uint32(vectorTable[i])
            hash2 += uint32(vectorTable[7-i])
        }
        binary.LittleEndian.PutUint32(result[:4], hash1)
        binary.LittleEndian.PutUint32(result[4:], hash2)
        return result
    }
    `

    const cpp = `
    #include <stdio.h>
    #include <stdint.h>
    #include <string.h>

    uint8_t* reverseArray(uint8_t arr[], int length) {
        for (int i = 0; i < length / 2; i++) {
            int j = length - i - 1;
            uint8_t temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
        }
        return arr;
    }

    void OneWayHash(uint8_t pairkey[], uint8_t vectorTable[], uint8_t result[]) {
        uint32_t *hash1_ptr = (uint32_t *)reverseArray(pairkey, 4);
        uint32_t *hash2_ptr = (uint32_t *)reverseArray(pairkey + 4, 4);
        uint32_t hash1 = *hash1_ptr;
        uint32_t hash2 = *hash2_ptr;

        for (int i = 0; i < 8; i++) {
            hash1 ^= (hash2 << 2) + vectorTable[i];
            hash2 ^= (hash1 << 2) + vectorTable[i];
            hash1 += vectorTable[i];
            hash2 += vectorTable[7-i];
        }

        *((uint32_t*)result) = hash1;
        *((uint32_t*)(result + 4)) = hash2;
    }

    int main() {
        uint8_t key[] = {0x20,0x15,0xA9,0x8C,0x06,0xF8,0xE3,0x58};
        uint8_t vec[] = {0xDA,0xAF,0xA9,0xD7,0x95,0xA4,0x79,0x2E};
        uint8_t result[8];

        OneWayHash(key, vec, result);
        for (int i = 0; i < sizeof(result); i++) {
            printf("%02X ", result[i]); // will print: B6 95 79 58 EE EA C5 07
        }
        return 0;
    }
    `

    const csumm = `func csum(data []byte) byte {
    var result byte
    for _, b := range data {
        result ^= b
    }
    return 0x3F ^ result
}`

    return (
        <>
            <h1><ElectricBoltIcon /> Pairing Concept</h1>

            <p>
                Since the pairing protocols of supported CAM modules are not open, we developed our own that can implement the operation of a smartcard only in its receiver (STB).
                On this page, we will describe its implementation.
            </p>
            <p>
                The concept of TVCAS4 pairing is that the smartcard and the receiver store the same pairing key <code>pairkey</code>,
                consisting of <b>8 random bytes</b>. This key is generated by the smartcard during "pairing activation" (first run).
                This is why the first run is recommended to be performed by the <b>system operator</b>, to avoid interception of the <code>pairkey</code> by the subscriber
                using tools like a "grabber", digital analyzer, etc. The pairing key generated by the smartcard is saved in the non-volatile memory
                of the set-top box (STB). The pairing is considered successful if the <code>resultHash</code> from the receiver matches the <code>resultHash</code> calculated inside the smartcard.
            </p>

            <Grid container>
                <Grid item xs={6}>
                    <img src="/sources/diagram_pair_conax.svg" width="95%" alt="Pair tvcas4" />
                </Grid>
                <Grid item xs={6}>
                    <img src="/sources/diagram_pair_conax2.svg" width="95%" alt="Pair tvcas4" />
                </Grid>
            </Grid>

            <p>
                The post-activation algorithm is essentially the same as during activation.
                However, instead of <code>pairkey</code>, an empty array of 8 bytes <code>00 00 00 00 00 00 00 00</code> is transmitted.
                In any case, the APDU of this exchange must be conducted during the smartcard initialization.
                For example, right after the <code>ATR</code> signal, but no later than the first ECM. Otherwise, the ECM response will be an error:
            </p>

            <pre style={{padding:"10px 20px", backgroundColor:"#eaeaea", borderRadius:"3px"}}>&lt;    98 04<br />
&gt; DD CA 00 00 04<br />
&lt;    CA 31 02 00 00 90 00</pre>

            <p>
                Please note that after the pairing check, the card responds with 90 00 regardless of whether the check was passed or not.
                This is specifically designed to prevent brute-forcing keys, along with a 50 ms delay before the response.
                Pairing mode is not a panacea, but in the hands of an operator, it creates significant difficulties for card sharing enthusiasts,
                as the <code>pairkey</code> can be changed over time.. Additionally, with sufficient interest from equipment manufacturers,
                an extended version of the pairing protocol with a dynamic <code>pairkey</code> is available.
                Discussion on this topic is possible only after signing a <u>non-disclosure agreement</u> (NDA).
            </p>

            <Grid container>
                <Grid item xs={6}>
                    <Alert style={{marginRight:"10px"}} severity="info">
                        <AlertTitle>TVCAS4 (CON)</AlertTitle>
                        <pre>&gt; DD CB 00 00 10<br />
&lt; CB <span style={{color:"red", fontWeight:"bold"}}>CE 74 12 3C 05 AB D9 69</span> <span style={{color:"#02c0c0", fontWeight:"bold"}}>E1 8D 83 FC D7 61 E8 1D</span> 9000<br />
&gt; 13 08 <span style={{color: "#df157a", fontWeight:"bold"}}>14 B0 5B 18 29 64 62 8F</span>  <span style={{color:"gray"}}> (wait 50 msec)</span><br />
&lt; 90 00</pre>
                    </Alert>
                </Grid>
                <Grid item xs={6}>
                    <Alert severity="info">
                        <AlertTitle>TVCAS4 (IRD)</AlertTitle>
                        <pre>&lt; 3B9F210E49524445544F204143532056352E3895      <span style={{color:"gray"}}> ATR</span><br />
&gt; 0102CB000000 F7                               <span style={{color:"gray"}}> F7 (last byte) - control summ</span><br />
&lt; 01020000CB000010 <span style={{color:"red", fontWeight:"bold"}}>DAAFA9D795A4792E</span> <span style={{color:"#02c0c0", fontWeight:"bold"}}>2015A98C06F8E358</span> 9000<br />
&gt; 0102CB000000 1B</pre>
                    </Alert>
                </Grid>
            </Grid>


            <p>
                The last byte in each TVCAS4 APDU request (response) (IRD) is a checksum. It is calculated as the XOR of all previous bytes with the byte <code>0x3F</code>.
            </p>
            <SyntaxHighlighter language="go">{csumm}</SyntaxHighlighter>

        
            <h2>Software Implementation</h2>

            <PairCalc />

            <p>
                For pairing tests, you can <ImageModal url="/sources/apdupair.jpg">use</ImageModal> the console program <a href="/apdupair.exe" target="_blank" rel="noreferrer">apdupair.exe</a>.
                Below are the implementations of the <b>OneWayHash()</b> - a one-way key hashing function in different programming languages:
            </p>



            <h3>Go Implementation</h3>
            <SyntaxHighlighter language="go">
                {golang}
            </SyntaxHighlighter>

            <h3>C Implementation</h3>
            <SyntaxHighlighter language="c">
                {cpp}
            </SyntaxHighlighter>
            
            <h2><ElectricBoltIcon /> Pairing Indicator on Smartcard</h2>
<p>You can check if pairing is enabled on the smartcard using the <ImageModal url="/sources/apdupair.jpg">aforementioned</ImageModal> program <a href="/apdupair.exe" target="_blank" rel="noreferrer">apdupair.exe</a>.
Also, on the [CON] smartcard, at the end of the <ImageModal url="/sources/pair_con.jpg">trademark</ImageModal>, a "+" will be displayed when the card is waiting for pairing activation, and a "*" when pairing is enabled and activation is completed.<br />
On cards with [IRD] firmware, pairing information can be obtained from <ImageModal url="/sources/pair_ird.jpg">uploader.exe</ImageModal>.</p>
<Dalee url="license" />

        </>
    )
}
