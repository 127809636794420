import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle } from '@mui/material';
import YouTube from 'react-youtube';
import 'App.css'
import ImageModal from 'components/ImageModal';
import Dalee from 'components/DaleeBtn';

export default function Features(){


    return (
        <><h1><ElectricBoltIcon /> Смарткарты</h1>


            <p>
                Безопасность в системе TVCAS4 обеспечивается использованием специальных смарткарт, которые также используются в банковском секторе
                и у мобильных операторов <a href="http://tsinghuaic.com/product/product.asp?id=12&bid=8552" target="_blank" rel="noreferrer">THC20F17BD-V40</a>.
                Мануал к ним находится <a href="/sources/DS3802-THC20F17BD-V40-EN-US.pdf">здесь</a>.
                Чистые смарткарты можно заказать <a href='https://aliexpress.com/item/1005002117450387.html' target="_blank" rel="noreferrer">здесь</a> или <a href="https://t.me/emfaz" target="_blank" rel="noreferrer">здесь</a>.
                При заказе у других продавцов, обязательно уточняйте, какой ATR у приобретаемых смарткарт.
                Так как производитель в 2024 году перестал выпускать карты THC20F17
                и они остались только в виде запасов на складах,
                то начиная с версии TVCAS4 осуществлена поддержка смарткарт <b>THC20F150A</b>
                - это более новая разработка этого семейства, отличающаяся только бОльшим оъёмом FLASH-памяти.
            </p>

            <center>
                <img src='/sources/thc20f17bd-v40.jpg' alt='' width='60%' /><br />
                Смарткарты разных форм-факторов
            </center>


            <Alert severity="warning" style={{margin:"20px 0 20px"}}>
                <AlertTitle>ATR чистых смарткарт</AlertTitle>
                <div>THC20F17BD-v40 = <span style={{color:"green"}}>3B1F96380503200617020440100007089000</span></div>
                <div>THC20F150A = <span style={{color:"green"}}>3B1F963C0503200601500110100007089000</span></div>
            </Alert>

            <h3>Программирование смарткарт</h3>
            <p>
                Для программирования поддерживаемых смарткарт необходим стандартный Phoenix-программатор, типа <b>TBS3102</b>.
                Вы можете купить его <a href='https://aliexpress.com/item/32608636124.html' target='_blank' rel='noreferrer'>здесь</a>
                    или самостоятельно изготовить по <ImageModal url="/sources/phoenix-scheme.jpg">этой</ImageModal> схеме.
                Установки для программатора: <b>5V / 3.57 Mhz</b>
            </p>


            <center><h3>Сам процесс показан на видео</h3>
            <YouTube videoId={"y1A9-OJKo3w"} opts={{ width: '70%'}} />
            </center>

            <Alert severity="info" style={{margin:"20px 0 20px"}}>
                <AlertTitle>Обновление</AlertTitle>
                Сделан возможным процесс запуск загрузчика без парамеров. Для этого необходимо его скачать (начиная с версии 4.005)
                в футере панели управления. Ваши параметры: <b>IP:port</b>, <b>api-ключ</b> автоматически встраиваются в бинарный файл,
                что <ImageModal url="/sources/uploader_wo_params.jpg">даёт такую возможность</ImageModal>. Обратите внимание, что передавать кому-либо свой файл <b>uploader.exe</b> нельзя,
                чтобы не допустить утечки доступа к вашему серверу. Вариант с парамерами тоже остался возможным. 
            </Alert>

            <hr />

            <h3>Смена ECM/EMM ключей на смарткартах</h3>
            <p>
                Начиная с версии 4.018 стала доступна возможность сменить ключи ECM/EMM на ваших смарткартах, при условии, что они были прошиты
                загрузчиком версии не ниже 2.010. Такая процедура может потребоваться, например, если вдруг ваши ключи были скомпрометированы.
                Смена ключей является осторожным мероприятием, поэтому желательно, чтобы Вы отработали последовательность действий на тестовой системе:
                <ul>
                    <li>Выберите период времени (5-7 дней) относительно спокойный от смены пакетов у абонентов (потому что подписки будут идти медленно, только через <b>ECMGIFT</b>)</li>
                    <li>Запустите свой сервер TVCAS4 в режиме «master»: <code>./server_v.4.xxx master</code><br />
                        <i>При таком режиме меню панели управления станет <b style={{color:"red"}}>красным</b>,
                        сигнализируя об осторожном использованнии данного режима!</i></li>
                    <li>Зайдите в конфигурационный раздел и измените свой ECM-ключ на любой другой. Нажмите кнопку «Сохранить»</li>
                    <li>Оставьте сервер, работающий в режиме «master» на 5-7 дней, чтобы физические смарткарты ваших абонентов приняли новый ECM-ключ</li>
                    <li>После того, как бОльшая часть смарткарт обновит ECM-ключ, можете перевести свой сервер в обычный режим работы,
                        а созданный мастером <b>ECMG со старым ключём</b> оставить на одном из своих телеканалов:
                        таким образом в будущем вы сможете обновлять смарткарты, которые не были активны в период основного обновления.
                    </li>
                </ul>
                <b>Внимание!</b> Выше описан процесс обновления ECM-ключа. Чтобы обновить все ключи ECM/EMM обратитесь за консультацией к автору. Услуга платная.
            </p>

            <hr />

            <h3>Виртуальные смарткарты для платформы ARM64</h3>
            <p>
                Виртуальная смарткарта представляет собой программу (исполняемый файл), запускаемый на Set-Top-Box, построенных на базе процессоров ARM64.
                Программа похожа на виртуальный CAM (работает также по <b>newcamd</b>), только для её работы не требуется интернет-соединение.
                Подписки приходят по-средством EMM. Она полностью повторяет алгоритм работы настоящей физической смарткарты от TVCAS4. 
                Её можно <ImageModal url="/sources/smartcard_arm64.jpg">скачать</ImageModal> в окне редактирования подписок смарткарты вашей панели управления.
                После копирования на STB не забудьте выставить права на запуск (0777). 
            </p>
            <p>
                Программа <ImageModal url="/sources/raspberry_arm64.jpg">поднимает соединение</ImageModal> по протоколу <b>newcamd</b> на порту <code>:12345</code>.
                Логин/пароль для подключения соответствует номеру вашей смарткарты (10 символов).<br />
                Необходимо учитывать, что в целях безопасности, виртуальная смарткарта изначально скачивается с отключенными подписками!
                Для её активации дождитесь своей EMM (или ECMGIFT) с подпиской.
                Для <ImageModal url="/sources/virtual_smartcard_log.jpg">противодействия шаринга</ImageModal> данной виртуальной смарткарты реализован лимит обращений к ней (антишаринг).
            </p>

            <hr />

            <h3>Стиратель смарткарт TVCAS4</h3>
            <p>
                <ImageModal url="/sources/eraser.jpg">Стиратель</ImageModal> - это устройство, внешне похожее на картридер, которое позволяет возвратить записанную смарткарту к заводской прошивке.
                В отличие от программного стирания (через панель управления TVCAS4), физический стиратель способен вернуть смарткарту даже если на ней включён флаг протекции.
                Устройство доступно к заказу только для клиентов с приобретённой лицензией.
            </p>

            <Dalee url="pairing" />

        </>
    )
}