import React from 'react'
import RedirectHome from '../components/RedirectHome'



export default function NotFoundPage(props){

    return(
        <>
            <h2>ERROR 404! Not found!</h2>
            <RedirectHome />
        </>
    )
}