import React, { useState } from 'react'
import { Alert, Button, Grid, Link } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';
import { useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { NavLink } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function Home(){

    const [verDate, setVerDate] = useState("null")

    useEffect(function(){
        fetch("/ext.php?op=verDate")
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // или response.text() если ожидается другой тип данных
        })
        .then(data => {
            if(data.length>25) data = "null"
            setVerDate(data)
        })
        .catch(error => {
            // Обработка ошибок
            console.error('There was a problem with the fetch operation:', error);
        });
    }, [])

    return (
        <>
            <h1>
                <Grid container>
                    <Grid item xs={6}>
                        <ElectricBoltIcon />Текущая версия: TVCAS v{verDate}
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right", paddingRight:"10px"}}>
                        <NavLink to="/tvcas4.tar.gz" className="Navlink" target="_blank" rel="noopener noreferrer" style={{paddingRight:10}}>
                            <Button variant='contained' color="error"><CloudDownloadIcon /> <span style={{paddingLeft:"10px"}}>скачать</span></Button>
                        </NavLink>
                        <NavLink to="http://demo.tvcas.com" className="Navlink" target="_blank" rel="noopener noreferrer" style={{paddingRight:10}}>
                            <Button variant='contained' color="success"><OpenInNewIcon /> <span style={{paddingLeft:"10px"}}>демо</span></Button>
                        </NavLink>
                        <NavLink to="https://github.com/unidiag/tvcas4cam" className="Navlink" target="_blank" rel="noopener noreferrer">
                            <Button variant='contained'><GitHubIcon /> <span style={{paddingLeft:"10px"}}>Github</span></Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </h1>

            <Grid container mb={3} mt={5}>
                <Grid item xs={4}>

                    <div className="image-3d-container">
                        <img src='/sources/ui-smartcards.jpg' alt='TVCAS4 dashboard'  className="image-3d" />
                    </div>

                </Grid>
                <Grid item xs={8}> 
                    <p>
                        TVCAS4 - это система условного доступа (CAS), предназначенная для ограничения доступа к телеканалам в сетях эфиного, кабельного и спутникового вещания.
                        Проект руководствуется единым алгоритмом шифрования <Link rel="noreferrer" href='https://www.etsi.org/deliver/etsi_TS/103100_103199/103197/01.05.01_60/ts_103197v010501p.pdf' target='_blank'>SimulCrypt</Link>.
                        TVCAS4 успешно работает с распространёнными <ImageModal url="/sources/cam_modules.jpg">CAM-модулями</ImageModal> и также без них.
                        Данный проект - это попытка создать универсальный продукт по защите контента, дружественный к обширным потребностям операторов.
                        Процесс кодирования в TVCAS4 имеет свою собственную криптосистему, базирующуюся на Triple DES (128 бит).
                        Также, с недавнего времени мы добавили возможность работы системы <b>без смарткарт</b>, через так называемые «<ImageModal url="/sources/VirtualCAM.svg">виртуальный CAM</ImageModal>» и «виртуальную смарткарту» по протоколу <code>newcamd525</code>.
                    </p>
                    <p>
                        Скорость обмена между смарткартой и CAM-модулем не должна отличаться от 9600 бод.
                        TVCAS4 также дружественен к приёмникам, т.н. сет-топ-боксам (STB), условный доступ в которых осуществлён программным способом с использованием OSCam, CCcam, Wicard и тп
                    </p>

                </Grid>
            </Grid>



            
            <h3>Главные отличия от TVCAS3</h3>
            <div style={{padding: "10px 20px"}}>🔹 Проект написан на языке <a href="https://go.dev" target="_blank" rel="noreferrer">Golang</a> (backend) с использованием технологий <a href="https://react.dev" target="_blank" rel="noreferrer">ReactJS</a> + <a href="https://mui.com" target="_blank" rel="noreferrer">MaterialUI</a> (frontend).
                Это значит, что теперь вам не требуется наличие Apache2, PHP, MySQL и т.п.
                Сервер TVCAS4 состоит из одного единственного исполняемого файла.
                Вам необходимо просто запустить его в системе Linux (Debian 11 и новее) и всё будет готово к работе.</div>
            <div style={{padding: "10px 20px"}}>🔹 Добавлена поддержка новых карт THC20F150A. Никаких дополнительных действий от вас не потребуется. Вы работаете с картами F17BD и F150A как с одними и теми же. TVCAS4 сама решает за вас проблемы согласования.</div>
            <Alert severity="info" style={{padding: "10px 20px"}}><b>Будьте внимательны!</b> Карты F150A могут прошиваться старым аплоадером от TVCAS3, но вернуть их к состоянию BLANK не сможет ни <ImageModal url="/sources/eraser.jpg">физический стиратель</ImageModal>, ни программный от TVCAS4. После попытки такого стирания она превращается в «кирпич».</Alert>
            <div style={{padding: "10px 20px"}}>🔹 Возможность работы без EMM. Информация о подписках передаётся в ECM (т.н. функция <b>ecmgift</b>) — минус в том, что медленнее, плюс - в том, что никакая карта не обойдёт стороной свою фактическую подписку..</div>
            <div style={{padding: "10px 20px"}}>🔹 Поддержка CAM-модулей Irdeto SmarDTV P/N 904505 R1.3. Caid: 0602 (RUS).</div>
            <div style={{padding: "10px 20px"}}>🔹 Если карта на протяжении работы получит более 65535 штук ECM и при этом ни одной EMM (или <b>ecmgift</b>) - она блокируется, дабы противодействовать отключению EMM со стороны абонента. Смарткарта начнёт открывать каналы сразу же после прихода первой EMM (или <b>ecmgift</b>-а).</div>
            <div style={{padding: "10px 20px"}}>🔹 Настоящий <NavLink to="/pairing">паринг</NavLink> по собственному протоколу.</div>
            <div style={{padding: "10px 20px"}}>🔹 Безболезненная миграция с TVCAS3 на новую версию TVCAS4 через файлы экспорта/импорта. Карты не обязательно менять, но нововведения на старопрошитых картах работать не будут. Для конвертации ECM-ключа используйте <a href="/converter.exe">converter.exe</a> в архиве.</div>
            <div style={{padding: "10px 20px"}}>🔹 Отключение счётчика запросов и возврат карты в BLANK теперь происходит программным способом (функция доступна после покупки лицензии)</div>
                        
            <Dalee url="features" />
        </>
    )
}